.card {
  max-width: calc(25% - 16px);
}

.Slider {
  ul {
  }
  li {
    display: none;
  }
  li:has(div) {
    display: unset;
  }
}

.border {
  img {
    border: 2px solid;
    border-color: var(--color);
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.defImg {
  img {
    aspect-ratio: 1;
    object-fit: cover;
  }
}

.bg {
  background-color: var(--bg);
}

.bgHalf {
  background: linear-gradient( var(--bg ) 50%, #fff 50%);
}
