.dottedSpan {
  background-color: var(--color);
}

.dottedButton {
  border-color: var(--color);
}

.numberedSpan {
  color: var(--color);
}

.numberedSquareSpan {
  color: var(--color);
  border-color: var(--color);
}

.labelledSpan {
  color: var(--color);
}

.active {
  background-color: var(--activeColor);
  @apply w-6 lg:w-8;
}

.activeLine {
  background-color: var(--activeColor);
}

.tracerLine {
  background-color: var(--color);
}

.activeCustom {
  background-color: var(--activeColor);
  background-size: cover;
  -webkit-mask: var(--shape) no-repeat center;
  mask: var(--shape) no-repeat center;
  -webkit-mask-size: cover;
  mask-size: cover;
}

.custom {
  background-color: var(--color);
  background-size: cover;
  -webkit-mask: var(--shape) no-repeat center;
  mask: var(--shape) no-repeat center;
  -webkit-mask-size: cover;
  mask-size: cover;
}
