.underline {
  background-color: var(--line);
}

.underlineCustom {
  background-color: var(--line);
  background-size: auto;
  -webkit-mask: var(--shape) no-repeat left;
  mask: var(--shape) no-repeat left;
  -webkit-mask-size: cover;
  mask-size: auto;
}
