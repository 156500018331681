.mobile-menu-button {
  svg {
    @media (max-width: 650px) {
      width: 30px;
    }
  }
}

.borderFull {
  border-color: var(--cta);
  color: var(--color);
}

.default {
  color: var(--color);
}

.bgFill {
  background-color: var(--cta);
  color: var(--color);
}

.defaultLine {
  background-color: var(--color);
}

.bottom {
  color: var(--color);
  @apply border-b py-0 hover:scale-110 transition-all duration-500;
  border-color: var(--color);
}

.round {
  background-color: var(--cta);
  color: var(--color);
}

.ctaIcons {
  @apply w-[30px] h-[20px];
  background-color: var(--svgColor);
  background-size: contain;
  -webkit-mask: var(--svg) no-repeat center;
  mask: var(--svg) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
}

.burger {
  background-color: var(--color);
  background-size: contain;
  mask: var(--burger) no-repeat center;
  -webkit-mask: var(--burger) no-repeat center;
  mask-size: contain;
  -webkit-mask-size: contain;
  @apply w-[2.5rem] h-[2.5rem];
}

.close {
  background-color: var(--color);
  background-size: contain;
  -webkit-mask: var(--close) no-repeat center;
  mask: var(--close) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  @apply w-[2.5rem] h-[2.5rem];
}

.btnHover {
  @apply transition-all duration-500;
  &:hover {
    color: var(--cta);
    background-color: var(--color);
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  }
}
