@import "../../../styles/mixins.scss";

// BASE STYLES //



.innerTextContainer {
  @apply flex-none lg:col-span-1  flex flex-col gap-y-4  lg:mb-0 self-center;
}

.smallerContainer {
  @apply lg:max-w-[25rem] mr-auto lg:mx-auto;
}

.smallInnerContainer {
  @apply md:pb-12  md:translate-y-[0] xl:translate-y-[-25%];
}

.textRight {
  @apply lg:order-2;
}

.textLeft {
  @apply lg:order-1;
}

.parallaxImage {
  div {
    transform: translate(55%);
  }
}

.imageFix {
  div {
    @apply w-[100%] md:w-auto;
    img {
      object-fit: contain;
      margin: auto;
    }
  }
}

.imageFixHeight {
  div {
  }
  img {
    object-fit: contain;
  }
}

.parallaxImagesFirst {
  @apply translate-y-[-20%] lg:translate-y-[0%];
  img {
    @apply max-w-[600px];
  }
}

.parallaxImagesLast {
  div {
    @apply translate-x-[-10%] lg:translate-x-[40%]  translate-y-[35%] lg:translate-y-[70%];
  }
  img {
    @apply object-contain max-h-[15rem] lg:max-h-max max-w-[250px] md:max-w-full;
  }
}

.parallaxLess {
  @apply translate-x-[-30%]  translate-y-[10%] lg:translate-x-[20%] lg:translate-y-[25%];

  img {
    @apply max-w-[200px] md:max-w-[100%];
  }
}

.parallaxMore {
  @apply translate-x-[-20%]  translate-y-[40%]    xl:translate-x-[35%] lg:translate-y-[50%] xl:translate-y-[100%];

  img {
    @apply max-w-[25rem] xl:max-w-[28rem];
  }
}

.parallaxMain {
  @apply lg:translate-x-[0%] lg:translate-y-[0%];
}

.mobileDisplayCopy {
  @apply order-1;
}
