.formControl {
  @apply p-2 py-4   block w-full mt-2;
  color: var(--color);
  background-color: var(--bg);
}
.formControlDrop {
  @apply p-2 py-4  mt-1 block w-full;
  color: var(--color);
  background-color: var(--bg);
}

.formTextArea {
  @apply mt-1 block w-full   p-2;
  color: var(--color);
  background-color: var(--bg);
}

.formCheck:has(input:required:not(:checked)) {
  p {
    span{
      color: red!important;
    }

  }
}

.formCheck {
  @apply mt-2;
  position: relative;
  padding-left: 2.5rem;

  span {
    color: var(--color);
  }

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .check {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: white;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .check {
    background-color: var(--bg);
  }

  &:hover input ~ .check:after {
    display: block;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid var(--color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .check {
    background-color: var(-bg);
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .check:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:checked ~ .check:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .check:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid var(--color);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.formSelect {
  @apply mt-1 block w-full  p-2;

  color: var(--color);
  background-color: var(--bg);
}

.bg {
  background-image: var(--img);
  background-size: cover;
  background-color: var(--bg);
}

.label {
  position: absolute;
  left: 0.5rem;
  top: -1rem;
  background-color: var(--style);
  color: white;
  @apply px-4 py-1 rounded-sm;
}
