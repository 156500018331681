.bgCover {
  position: absolute !important;
  top: 0;
  left: 0;
  height: 100%;
  max-width: 100% !important;
  object-fit: cover;
  img {
    object-position: calc(var(--x) * 100) calc(var(--y) * 100);
    height: 100% !important;
    @apply object-cover;
  }
}

.bgContain {
  position: absolute !important;
  top: 0;
  height: 100%;
  img {
    height: 100% !important;
    object-fit: contain;
    object-position: right;
    @media (max-width: 1450px) {
      object-position: center;
      object-fit: cover;
    }
    @media (max-width: 768px) {
      object-position: center;
    }
  }
}

.imageGallery {
  img {
    object-fit: cover;
  }
}

.customStyle {
  @media (min-width: 1024px) {
    img {
      @apply pt-[var(--style)];
    }
  }
}
