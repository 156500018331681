.bannersGrid {
  @apply grid grid-cols-1 md:grid-cols-2 gap-6 px-6  lg:px-0;

  .featuredBannerContainer:nth-child(even) {
    @apply lg:translate-y-[-50%];
  }
  .featuredBannerContainer:last-child {
    @apply lg:col-start-2 lg:translate-y-[-50%];
  }
}

.featuredBannerContainer {
  @apply relative min-h-[560px] flex flex-col justify-end;
}
