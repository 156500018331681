.itemClass {
  .react-multi-carousel-item--active {
  }
}

.slider {
  li[aria-hidden="true"] {
  }
  li {
    transition: 0.5s;
  }
}
