.shapeTop {
	@apply h-20 w-full;
	background-color: var(--color);
	background-size: cover;
	background-repeat: repeat-x;
	background-position: center;
	-webkit-mask: var(--shape) repeat-x center;
	mask: var(--shape) repeat-x center;
	-webkit-mask-size: cover;
	mask-size: cover;
	width: var(--width);
	height: var(--height);
}

.shape {
	@apply w-full absolute;
	background-color: var(--color);
	background-size: contain;
	background-position: center;
	mask: var(--shape);
	-webkit-mask: var(--shape) no-repeat center;
	mask-size: cover;
	-webkit-mask-size: contain;
	z-index: 1;
	height: calc(var(--height) * 1.2);

	@media (min-width: 1024px) {
		height: calc(var(--height) * 1.2);
	}
	@media (min-width: 1450px) {
		height: calc(var(--height) * 1.5);
	}
	@media (min-width: 1750px) {
		height: calc(var(--height) * 1.8);
	}
}

.icon {
	margin-top: -3rem;
	@media (min-width: 1024px) {
		margin-top: -4.5rem;
	}
	@media (min-width: 1450px) {
		margin-top: -5.5rem;
	}
}
