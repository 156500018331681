.Slider {
  overflow: visible;
  position: unset !important;
  ul {
    height: 100%;
  }
}

.imageH {
  max-width: 225px !important;
  @media (max-width: 1024px) {
    max-width: 155px !important;
  }
}

.SliderTrack {
  li[aria-hidden="false"] {
    transform: scale(1);
    div {
      max-width: 500px !important;
    }
    @media (max-width: 768px) {
      transform: scale(1);
    }
    .activeDiv {
      left: -12rem;
      @media (max-width: 768px) {
        left: 0;
      }
    }
    article {
      display: flex;
      @media (max-width: 768px) {
        width: 145%;
        left: 0;
        transform: translateX(-16%);
      }
    }
  }
  li {
    transform: scale(0.6);
    transition: 0.5s;
    display: flex;
    justify-content: center;
    div {
      max-width: 250px !important;
    }
    @media (max-width: 768px) {
      transform: scale(1);
    }
  }
}

.sliderImage {
  img {
    object-fit: contain !important;
  }
}
