.imageContainerGrid {
  position: relative;
  div:last-child {
    height: 100%;
  }
  img {
    object-fit: cover;
  }
}

.imageContainer {
  img {
    object-position: calc(var(--x) * 100) calc(var(--y) * 100);
    height: 100% !important;
    @apply object-cover;
  }
}
