@import "../../../styles/mixins.scss";

.boxShadowEnhancer {
	z-index: 1;
	@include respond(desktop) {
		background: linear-gradient(
			180deg,
			rgba($blue, 0.7) 40%,
			rgba($blue, 0) 100%
		);
	}
}

.boxShadow {
	z-index: 1;
	background: linear-gradient(180deg, var(--bg) 40%, transparent 100%);
	@apply opacity-70;
}

// STRONG SHADOW

.boxShadowStrongDarkLeft {
	z-index: 1;
	background: linear-gradient(36deg, var(--bg) 35%, transparent 85%);
	@apply opacity-70 lg:opacity-100;
	@include respond(desktop) {
		background: linear-gradient(90deg, var(--bg), transparent);
	}
}

.boxShadowStrongDarkLeftVideo {
	z-index: 1;
	background: linear-gradient(90deg, var(--bg) 100%, transparent 100%);
	@apply opacity-70 lg:opacity-100;
	@include respond(desktop) {
		background: linear-gradient(90deg, var(--bg), transparent);
	}
}

.boxShadowStrongDarkCenter {
	z-index: 1;
	background: linear-gradient(0deg, var(--bg) 100%, transparent 100%);
	@apply opacity-70 lg:opacity-100;
	@include respond(desktop) {
		background: linear-gradient(0deg, var(--bg), transparent);
	}
}

.boxShadowStrongDarkCenterVideo {
	z-index: 1;
	background: linear-gradient(9deg, var(--bg) 100%, transparent 100%);
	@apply opacity-70 lg:opacity-100;
	@include respond(desktop) {
		background: linear-gradient(0deg, var(--bg), transparent);
	}
}

.boxShadowStrongDarkRight {
	z-index: 1;
	background: linear-gradient(36deg, var(--bg) 35%, transparent 85%);
	@apply opacity-70 lg:opacity-100;
	@include respond(desktop) {
		background: linear-gradient(-90deg, var(--bg), transparent);
	}
}

.boxShadowStrongDarkRightVideo {
	z-index: 1;
	background: linear-gradient(90deg, var(--bg) 100%, transparent 100%);
	@apply opacity-70 lg:opacity-100;
	@include respond(desktop) {
		background: linear-gradient(-90deg, var(--bg), transparent);
	}
}

// END STRONG SHADOW

// SOFT SHADOW

.boxShadowSoftDarkLeft {
	z-index: 1;
	background: linear-gradient(90deg, var(--bg) 40%, transparent 100%);
	@apply opacity-40 lg:opacity-60;
	@include respond(desktop) {
		background: linear-gradient(90deg, var(--bg), transparent);
	}
}

.boxShadowSoftDarkLeftVideo {
	z-index: 1;
	background: linear-gradient(90deg, var(--bg) 100%, transparent 100%);
	@apply opacity-30 lg:opacity-60;
	@include respond(desktop) {
		background: linear-gradient(90deg, var(--bg), transparent);
	}
}

.boxShadowSoftDarkCenter {
	z-index: 1;
	background: linear-gradient(0deg, var(--bg) 100%, transparent 100%);
	@apply opacity-30 lg:opacity-60;
	@include respond(desktop) {
		background: linear-gradient(0deg, var(--bg), transparent);
	}
}

.boxShadowSoftDarkCenterVideo {
	z-index: 1;
	background: linear-gradient(9deg, var(--bg) 100%, transparent 100%);
	@apply opacity-30 lg:opacity-60;
	@include respond(desktop) {
		background: linear-gradient(0deg, var(--bg), transparent);
	}
}

.boxShadowSoftDarkRight {
	z-index: 1;
	background: linear-gradient(36deg, var(--bg) 35%, transparent 85%);
	@apply opacity-50 lg:opacity-60;
	@include respond(desktop) {
		background: linear-gradient(-90deg, var(--bg), transparent);
	}
}

.boxShadowSoftDarkRightVideo {
	z-index: 1;
	background: linear-gradient(-90deg, var(--bg) 100%, transparent 100%);
	@apply opacity-30 lg:opacity-60;
	@include respond(desktop) {
		background: linear-gradient(-90deg, var(--bg), transparent);
	}
}

// END SOFT SHADOW

// LIGHT SHADOW

.boxShadowLightDarkLeft {
	z-index: 1;
	background: linear-gradient(90deg, var(--bg) 40%, transparent 100%);
	@apply opacity-20 lg:opacity-30;
	@include respond(desktop) {
		background: linear-gradient(90deg, var(--bg), transparent);
	}
}

.boxShadowLightDarkLeftVideo {
	z-index: 1;
	background: linear-gradient(90deg, var(--bg) 100%, transparent 100%);
	@apply opacity-20 lg:opacity-30;
	@include respond(desktop) {
		background: linear-gradient(90deg, var(--bg), transparent);
	}
}

.boxShadowLightDarkCenter {
	z-index: 1;
	background: linear-gradient(0deg, var(--bg) 100%, transparent 100%);
	@apply opacity-20 lg:opacity-30;
	@include respond(desktop) {
		background: linear-gradient(0deg, var(--bg), transparent);
	}
}

.boxShadowLightDarkCenterVideo {
	z-index: 1;
	background: linear-gradient(0deg, var(--bg) 100%, transparent 100%);
	@apply opacity-20 lg:opacity-30;
	@include respond(desktop) {
		background: linear-gradient(0deg, var(--bg), transparent);
	}
}

.boxShadowLightDarkRight {
	z-index: 1;
	background: linear-gradient(90deg, var(--bg) 35%, transparent 85%);
	@apply opacity-20 lg:opacity-30;
	@include respond(desktop) {
		background: linear-gradient(-90deg, var(--bg), transparent);
	}
}

.boxShadowLightDarkRightVideo {
	z-index: 1;
	background: linear-gradient(-90deg, var(--bg) 100%, transparent 100%);
	@apply opacity-20 lg:opacity-30;
	@include respond(desktop) {
		background: linear-gradient(-90deg, var(--bg), transparent);
	}
}

// END LIGHT SHADOW
