.expanded {
	transition-duration: 500ms;
	transform: translateX(0);
}

.breadcrumbs {
	position: absolute;
	top: 50px;
	font-size: 16px;

	li {
		display: inline-block;
		transition: color 0.2s;

		&:hover {
			cursor: pointer;
			color: var(--secondary);
		}

		&::after {
			content: "<";
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px;
		}

		&:last-child::after {
			display: none;
		}

		&:last-child:hover {
			cursor: default;
			color: inherit;
		}
	}
}

.content {
	position: absolute;
	z-index: 20;
	top: 120px;

	li {
		margin-bottom: 1rem;
		cursor: pointer;
		transition: font-weight 0.3s;
		&:hover {
			//font-weight: bold;
			color: var(--secondary);
		}
		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
		}
	}
}

.contentSidebar {
	li {
		margin-bottom: 1rem;
		cursor: pointer;
		transition: font-weight 0.3s;
		&:hover {
			//font-weight: bold;
			color: var(--secondary);
		}

		img {
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
		}
	}
}

.infowindow {
	background-color: var(--secondary);
}

.countryLocations {
	margin-bottom: 1rem;

	h4:hover {
		color: var(--secondary);
	}
}

.active {
	color: var(--secondary);
}

.countryName {
	&:hover {
		color: var(--secondary);
	}
}
