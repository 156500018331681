.obCover {
  object-fit: cover;
}

.animate {
  transition: 0.5s ease-in;
}

.obContain {
  object-fit: contain;
  transform: scale(1);
}

.gradient {
  object-fit: cover;
  background: linear-gradient(346deg, #000 11%, transparent 30%);
  opacity: 0.7;
}

.overlayVideo {
  width: 100%;
  height: 100%;
  background: linear-gradient(346deg, #000 10%, transparent 50%);
  position: absolute;
  opacity: 0.8;
}
