.ObliqueSpacer {
  @apply absolute top-[-2rem] lg:top-[-5rem] xl:top-[-7rem] h-[4rem] lg:h-[auto] w-full ;
  @screen md {
    transform: matrix(2, 0, 0, 1, 0, 0);
  }
}

.ObliqueCard {
  @apply absolute w-full top-[-3rem]  2xl:top-[-2rem]   h-[4rem] lg:h-[auto] left-0;
}
