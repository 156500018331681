.lineButton {
  color: var(--color);
}

.lineSpan {
  background-color: var(--color);
}

.customButton {
  background-color: var(--bg);
  z-index: 1;
}

.customButtonBg {
  background-color: var(--bg);
  background-size: contain;
  -webkit-mask: var(--svgBg) no-repeat center;
  mask: var(--svgBg) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  @apply absolute w-full h-[110px] md:h-[100px];
}

.iconElement {
  @apply w-[3.5rem] h-[3.5rem] md:w-[6.5rem] md:h-[6.5rem];
  background-color: var(--color);
  background-size: contain;
  mask: var(--svg) no-repeat center;
  -webkit-mask: var(--svg) no-repeat center;
  mask-size: contain;
  -webkit-mask-size: contain;
  z-index: 1;
}

.iconSvgElement {
  background-repeat: no-repeat;
  @apply w-[6rem] h-[4rem] md:w-[10rem] md:h-[8rem];
  background-color: var(--color);
  background-image: var(--svg);
  background-size: contain;
  z-index: 1;
}

.iconElementBg {
  @apply w-[1rem] h-[1rem] md:w-[2rem] md:h-[2rem];
  background-color: var(--color);
  background-size: contain;
  -webkit-mask: var(--svg) no-repeat center;
  mask: var(--svg) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  z-index: 1;
}
