.addressIcon {
  position: relative;
  background-color: var(--style);
  background-size: contain;
  -webkit-mask: var(--address) no-repeat center;
  mask: var(--address) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  @apply w-[2.5rem] h-[2.5rem];
}

.geoIcon {
  position: relative;
  background-color: var(--style);
  background-size: contain;
  -webkit-mask: var(--geo) no-repeat center;
  mask: var(--geo) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  @apply w-[2.5rem] h-[2.5rem];
}

.phoneIcon {
  position: relative;
  background-color: var(--style);
  background-size: contain;
  -webkit-mask: var(--phone) no-repeat center;
  mask: var(-phone) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  @apply w-[2.5rem] h-[2.5rem];
}

.faxIcon {
  position: relative;
  background-color: var(--style);
  background-size: contain;
  -webkit-mask: var(--fax) no-repeat center;
  mask: var(-fax) no-repeat center;
  -webkit-mask-size: contain;
  mask-size: contain;
  @apply w-[2.5rem] h-[2.5rem];
}

.copyContainer {
  position: relative;
  @apply flex flex-col justify-center;
  &::before {
    content: "";
    position: absolute;
    margin-left: -1rem;
    background-color: var(--style);
    width: 1px;
    height: 100%;
  }
}
