.filter {
  background: var(--filterBg);
}

.labelFilter {
  background: var(--filterBg);
  color: var(--label);
}

.btnFilter {
  border-color: var(--label);
}

.buttonFilterCat {
  color: var(--textColor);
  border-color: var(--textColor);
}


.activeBtn{
@apply border-4 font-bold
}