@import "../../../../styles/mixins.scss";

.Slider {
	@include respond(mobile-sm) {
		min-height: calc(75vh);
	}
	@include respond(tablet) {
		height: calc(90vh);
	}
	@include respond(mobile) {
		height: calc(65vh);
	}
	@include respond(desktop) {
		height: calc(85vh - 90px);
	}
	ul {
		height: 100%;
		position: absolute;
	}
}

.playing {
	height: calc(450px - 90px);
	min-height: calc(50vh);
	@include respond(tablet) {
		height: calc(100vh - 90px);
	}
	z-index: 10;
}
