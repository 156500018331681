.formControl {
  @apply border  py-2 px-3 rounded-lg mt-1 block w-full;
  border-color: var(--labelcolor);
  color: var(--color);
  background-color: var(--bg);
}

.formTextArea {
  @apply mt-1 block w-full border  py-2 px-3 rounded-lg;
  border-color: var(--labelcolor);
  color: var(--color);
  background-color: var(--bg);
}

.formSelect {
  @apply mt-1 block w-full border  py-2 px-3 rounded-lg;
  border-color: var(--labelcolor);
  color: var(--color);
  background-color: var(--bg);

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  cursor: pointer;

  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.35' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding: 0.5rem 2rem 0.5rem 0.75rem;
}

.formGroup {
  position: relative;
  input[text],
  input[textarea] {
    padding: 0.5rem;
  }
}

.bg {
  background-image: var(--img);
  background-size: cover;
  background-color: var(--bg);
}

.changeLabelPos {
  position: absolute;
  // color: #707C8B;
  color: var(--labelcolor);
  left: 0.5rem;
  padding: 0 0.25rem;
  transition: all 0.15s ease-out;
  top: 0.75rem;
  background-color: transparent;
}
input:focus ~ .changeLabelPos,
textarea:focus ~ .changeLabelPos,
.dontChangeLabelPos {
  position: absolute;
  background-color: transparent;
  // color: #707C8B;
  color: var(--labelcolor);
  left: 0.75rem;
  padding: 0 0.25rem;
  transition: all 0.25s ease-out;
  background-color: var(--bg);
  top: -0.3rem;
  font-size: small;
}
