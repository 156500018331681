.imageContainerGrid{
    position: relative;
    div:last-child{
        height: 100%;
    }
    img{
        object-fit: cover;
    }
}

.imageFit{
    img{
        object-fit: cover;
    }
}