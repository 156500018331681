// BASE STYLES //

.textContainer {
  @apply max-w-[1360px]  grid grid-cols-1  lg:grid-cols-2 relative mx-auto w-full px-6  lg:px-0  gap-12;
}

.parallaxImage {
  div {
    transform: translate(55%);
  }
}

.imageFix {
  div {
    max-width: 100% !important;
  }
}

.imageFixHeight {
  div {
  }
  img {
    object-fit: contain;
  }
}

.parallaxImagesFirst {
  top: -5rem;
}

.parallaxImagesLast {
  div {
    transform: translate(50%);
    top: 5rem;
  }
}

.doubleColumn {
  @apply lg:columns-2 gap-12;
}

.singleColumn {
  @apply columns-1;
}
