.borderFull {
  border-color: var(--cta);
  color: var(--color);
}

.spanOblique {
  background-color: var(--cta);
}

.cardContainer {
  background-color: var(--bg);
}

.underline {
  background-color: var(--line);
}

.obliqueCard {
  color: var(--color);
  background-color: var(--bg);
  
}
