.bg {
	background-color: rgba(var(--r), var(--g), var(--b), 0.5);
	@apply py-4 px-2;
}

.bgAlt {
	background-color: rgba(var(--rAlt), var(--gAlt), var(--bAlt), 0.2);
	@apply py-4 px-2;
}

.bgColor {
	background-color: var(--bgColor);
}

.bgImage {
	background-image: var(--bg);
	background-image: var(--bg);
	background-position: center;
	background-repeat-x: no-repeat;
	background-size: contain;
	background-repeat-y: repeat;
}

.accoladeImg {
	img {
		height: 150px;
		object-fit: contain;
	}
}

.highlight {
	span {
		strong {
			color: var(--highlight);
		}
	}
}

.img {
	img {
		aspect-ratio: auto;
		max-height: 25rem;
	}
}

.galleryImageHalf {
	div {
		height: 100%;
		aspect-ratio: var(--ar);

		img {
			object-position: calc(var(--x) * 100) calc(var(--y) * 100);
			object-fit: cover;
		}
	}
}

@mixin flex-center-items($columns) {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	& > * {
		flex: 0 0 calc(100% / #{$columns} - 16px);
		max-width: calc(100% / #{$columns} - 16px);
	}
}

.grid {
	@include flex-center-items(1);

	@media (min-width: 640px) {
		@include flex-center-items(2);
	}

	@media (min-width: 768px) {
		@include flex-center-items(3);
	}

	@media (min-width: 1024px) {
		@include flex-center-items(4);
	}
}
