.spanOblique {
  background-color: var(--cta);
}

.cardContainer {
  background-color: var(--bg);
}

.featuredBanner {
  @apply flex flex-col gap-4 justify-center items-center py-6 px-2 md:px-16 text-center relative;

}


.doubleColumn {
  @apply lg:columns-2 gap-12;
}

.singleColumn {
  @apply columns-1;
}
