@import "../../../styles/mixins.scss";

.aboveImage {
  div {
    margin: 0 !important;
    bottom: 0;
  }
}

.styleBg {
  img{
    object-fit: contain;
  }
  div {
    height: 100%;
    max-width: 15rem !important;
    @include respond(desktop) {
      max-width: 85% !important;
    }
  }
}

.behind {
  div {
    margin: 0 !important;
    bottom: 0;
    z-index: -1;
  }
}
