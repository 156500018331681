//$colorTrack: #2788d0 !default;
$colorTrack: transparent !default;
$colorThumb: orange !default;

$h: 1em;
$w: 100%;

@mixin track() {
	width: $w;
	box-sizing: border-box;
	height: $h;
	border-radius: calc($h/2);
	// margin-right: 1em;
	box-sizing: content-box;
	background: (
		radial-gradient(
			circle at calc($h/2) 50%,
			$colorTrack 0.3125em,
			$colorTrack 0.3125em,
			$colorTrack calc($h/2),
			transparent calc($h/2)
		),
		linear-gradient(90deg, transparent $h, $colorTrack $h) repeat-x 0 50%
	);
}

@mixin thumb() {
	border: none;
	border-radius: 50%;
	box-sizing: border-box;
	border: (solid 0.35em transparent) null (solid 0.25em transparent);
	width: $h + 0.3em;
	height: $h + 0.3em;
	background: $colorThumb;
	background-clip: content-box;
	position: relative;
	z-index: 10;
	margin-top: -0.1em;
	margin-left: 0.05em;

	background: #eddecd;
	box-sizing: content-box;
	border: 10px solid #862633;
	width: 10px;
	height: 10px;
	margin-top: -2.4em;
	@screen lg {
		border: 20px solid #862633;
		margin-top: -0.7em;
	}
}

.range {
	display: block;
	margin: 1em auto;
	padding: 0;
	background: transparent;
	font-size: 1em;
	cursor: pointer;
	width: $w;
	position: relative;

	&,
	&::-webkit-slider-runnable-track,
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
	}
	&:after {
		content: "";
		width: 1em;
		height: 1em;
		border-radius: 50%;
		background: $colorTrack;
		position: absolute;
		right: 0;
	}
	&:before {
		content: "";
		position: absolute;
		z-index: 0;
		left: 0;
		right: $h - 0.2em;
		height: $h;
		background: (
			radial-gradient(
				circle at calc($h/2) 50%,
				$colorTrack 0.3125em,
				$colorTrack 0.3125em,
				$colorTrack calc($h/2),
				transparent calc($h/2)
			),
			linear-gradient(90deg, transparent 0, $colorTrack 0) repeat-x 0 50%
		);
		background-size: 100% 1em, 100% 0.25em;
		box-sizing: border-box;
	}

	&::-ms-track {
		border: none;
		color: transparent;
	}
	&::-webkit-slider-thumb {
		@include thumb();
	}
	&::-moz-range-thumb {
		@include thumb();
	}
	&::-ms-thumb {
		@include thumb();
	}
	&::-ms-tooltip {
		display: none;
	}
	&:focus {
		outline: none;
	}
}
