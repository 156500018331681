.line {
  border-color: var(--color);
}

.bgColumn {
  background-color: rgba(var(--r), var(--g), var(--b), 0.5);
  @apply py-4 min-h-[4.5rem] px-2;
}

.bgColumnAlt {
  background-color: rgba(var(--rAlt), var(--gAlt), var(--bAlt), 0.2);
  @apply py-4 min-h-[4.5rem] px-2;
}
.highlightText {
  p {
    strong {
      color: var(--highlight);
    }
  }
}
