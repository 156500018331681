.dropdownMenu {
	background-color: var(--bg);
	&:focus-within {
		border: none !important;
		outline: none !important;
	}
	&:focus {
		border: none !important;
		outline: none !important;
	}
	&:focus-visible {
		border: none !important;
		outline: none !important;
	}
	.itemNav {
		&:hover {
			color: var(--color);
			//NEED TO CHANGE THIS TO SOME COLOR
			background-color: rgba(255, 255, 255, 0.6);
		}
	}
}

.navigationMenu {
	color: var(--color);
	background-color: var(--bg);
}

.navigationItem {
	background-color: var(--bg);
}
