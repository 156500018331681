

.shapeBottom {
    @apply h-24 w-full translate-y-[-50%] scale-[-1] ;
    background-color: var(--bg);
    background-size: cover;
    background-repeat: repeat-x;
    background-position: center;
    -webkit-mask: var(--shape) repeat-x center;
    mask: var(--shape) repeat-x center;
    -webkit-mask-size: cover;
    mask-size: cover;
  }

  .shapeBottomList {
    @apply h-24 w-full translate-y-[50%] scale-[-1] ;
    background-color: var(--bg);
    background-size: cover;
    background-repeat: repeat-x;
    background-position: center;
    -webkit-mask: var(--shape) repeat-x center;
    mask: var(--shape) repeat-x center;
    -webkit-mask-size: cover;
    mask-size: cover;
  }